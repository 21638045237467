<template>
  <v-dialog
    v-model="isOpen"
    max-width="400"
    persistent
  >
    <v-card>
      <v-card-title class="subheading">
        Enrollment File
      </v-card-title>
      <v-card-text>
        <v-select
          :items="biometrics"
          v-model="enrollment"
          label="Select Enrollment"
          item-value="captureType"
          item-text="friendly"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <BaseButton @clicked="canceled" color="accent" text="Cancel" />
        <BaseButton @clicked="clicked" color="accent" text="Send" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SendEnrollmentDialog',
  props: {
    open: Boolean,
    disabled: Boolean,
    biometrics: Array,
  },
  data: () => ({
    isOpen: false,
    enrollment: '',
  }),
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  watch: {
    open(val) {
      this.isOpen = val;
    },
  },
  methods: {
    clicked() {
      this.isOpen = false;
      this.$emit('send', this.enrollment);
    },
    canceled() {
      this.isOpen = false;
      this.$emit('cancel');
    },
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
    }
  },
};
</script>
